import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default function useJobOfferList(isTemplate) {
  const { i18n } = useI18nUtils();

  const refDocumentListTable = ref(null);
  // Table Handlers
  const tableColumns = [
    {
      key: 'checkbox',
      label: '',
      sortable: false,
      thClass: 'tbl-chk',
      tdClass: 'tbl-chk',
    },
    {
      key: 'name',
      label: i18n(`Name`),
      sortable: true,
      thClass: 'pl-1',
      tdClass: 'pl-1',
    },
    // { key: 'category', label: i18n(`Category`), sortable: true },
    { key: 'category', label: i18n(`Category`), sortable: true },
    { key: 'contractType', label: i18n(`Contract type`), sortable: true, thClass: "sk-header-content" },
    { key: 'created', label: i18n(`Created`), sortable: true },
    { key: 'applicants', label: i18n(`Applicants`), sortable: true },
    { key: 'status', label: i18n(`Status`), sortable: true },
    {
      key: 'action',
      label: i18n(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      sortable: false,
      stickyColumn: true
    },
  ];
  const sortBy = ref('id');
  const perPage = ref(10);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const category = ref({ value: "", text: "All" });
  const isSortDirDesc = ref(true);
  const status = ref('All');
  const group = ref(null);
  const offers = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });

  const refetchData = () => {
    refDocumentListTable.value.refresh();
  };

  watch(
    [
      currentPage, perPage, searchQuery, status, group, isSortDirDesc, category, sortBy,
    ],
    () => {
      refetchData();
    }
  );

  const fetchOffers = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value,
    };

    if (isTemplate) {
      params.templates = 1;
    }

    if (status.value != null && status.value.value !== '') {
      params['filters[status]'] = status.value.value;
    }
    if (category.value != null && category.value.value !== '') {
      params['filters[category]'] = category.value.value;
    }

    axios
      .get(`offers`, {
        params,
      })
      .then(({ data }) => {
        offers.value = data.data.offers;
        //console.log("Job offers", data.data.offers);
        callback(data.data.offers);
        totalDocuments.value = data.data.pagination.records_count;
      });
  };

  return {
    fetchOffers,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    category,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    status,
    group,
    sortBy,
    offers,
    refetchData,
  };
}
